import "./styles.css";

const FloatingIcon = () => {
  return (
    <a
      className="FloatingIcon"
      href="https://wa.me/11968808619"
      target="_blank"
      rel="noreferrer"
    />
  );
};

export default FloatingIcon;
