import React from 'react';
import "./styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img1 from "./imgs/image1.jfif";
import img2 from "./imgs/image2.jfif";
import img3 from "./imgs/image3.jfif";
import img4 from "./imgs/image4.jfif";

const CarouselSection = () => {
    return (
        <div>
            <h1 className='Carousel-Title'>Quem nós somos</h1>
            <div className='Carousel-Container'>
                <Carousel
                    width={'100%'}
                    renderThumbs={() => { }}
                    renderArrowNext={() => { }}
                    renderArrowPrev={() => { }}
                    autoPlay={true}
                    interval={2000}
                    infiniteLoop={true}
                    showStatus={false}
                >
                    <img className='Carousel-img' src={img1} />
                    <img className='Carousel-img' src={img2} />
                    <img className='Carousel-img' src={img3} />
                    <img className='Carousel-img' src={img4} />
                </Carousel>
            </div>
        </div>
    );
}

export default CarouselSection;
