import "./styles.css";

const Footer = () => {
  return (
    <footer>
      <div className="Footer-contact">
        <span>
          <a href="https://wa.me/11968808619" target="_blank" rel="noreferrer">
            (11) 96880-8619
          </a>
        </span>
        <span>
          <a
            href="https://mail.google.com/mail/?view=cm&source=mailto&to=topicamentos@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            topicamentos@gmail.com
          </a>
        </span>
      </div>
      <span>©2023 - TOP IÇAMENTOS</span>
    </footer>
  );
};

export default Footer;
