import './App.css';
import CarouselSection from './components/Carousel';
import Navbar from './components/Navbar';
import InfoSection from './components/InfoSection';
import Footer from './components/Footer';
import FloatingIcon from './components/FloatingIcon';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className='App-body'>
        <main>
          <div className='flex-container'>
            <CarouselSection />
            <InfoSection />
          </div>
        </main>
        <Footer />
      </div>
      <FloatingIcon/>
    </div>
  );
}

export default App;
