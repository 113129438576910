import "./styles.css";

const Navbar = () => {
  return (
    <nav className="Navbar">
      <div className="Navbar-logo-container" />
      <h1 className="Navbar-title">Top Içamentos</h1>
      <div className="Navbar-contact">
        <span>
          <a
            href="https://mail.google.com/mail/?view=cm&source=mailto&to=topicamentos@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            topicamentos@gmail.com
          </a>
        </span>
        <span>
          <a href="https://wa.me/11968808619" target="_blank" rel="noreferrer">
            (11) 96880-8619
          </a>
        </span>
      </div>
    </nav>
  );
};

export default Navbar;
