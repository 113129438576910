import React from 'react';
import "./styles.css"

const InfoSection = () => {
    return (
        <div className='Container'>
            <h1>Quem nós somos</h1>
            <p>
                <span>
                    A <b>Top Içamentos</b> é uma empresa líder em soluções de içamento para o setor industrial e de construção. Com uma equipe altamente qualificada e dedicada, oferecemos serviços personalizados e eficientes para atender às necessidades de cada cliente. Nós nos esforçamos para ser a escolha preferencial de nossos clientes, combinando a tecnologia de ponta com técnicas avançadas para garantir a segurança, a precisão e a eficiência em cada trabalho.
                </span>
                <span>
                    Nossa equipe possui ampla experiência em içamentos de cargas pesadas e complexas, <b>incluindo sofás, mesas, racks, pianos, tampos de vidro, banheiras, etc...</b> Nós nos orgulhamos de ser reconhecidos pela qualidade de nossos serviços e pela atenção aos detalhes, garantindo sempre a satisfação total de nossos clientes.

                    Com uma ampla gama de serviços, desde planejamento até execução, a <b>Top Içamentos</b> é a escolha certa para seu próximo projeto de içamento.
                </span>
                <span>
                    Localizada na cidade de São Paulo, a a <b>Top Içamentos</b> presta atendimento em toda a região, contando com extrema agilidade, eficiência e oferecendo um ótimo custo benefício.
                    &#160;<a href='https://wa.me/11968808619' target='_blank' rel="noreferrer">Contate agora mesmo</a>&#160;
                    para saber mais informações e obtenha um orçamento adequado às suas necessidades.
                </span>
            </p>
        </div>
    );
}

export default InfoSection;
